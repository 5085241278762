import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { InPageNavigationAwards } from "../components/inpage-navigation"

const Workshops = () => (
    <Layout>
        <Seo title="FEARS Awards" />
        <InPageNavigationAwards/>
        <div className="fluid-container">
            <div className="row">
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Awards</h1>

                    <p>Multiple different prizes will be awarded to researcher for their excelling research and presentation skills. Below you can read what we are looking for in your contributions for the different awards.</p>
                    <p>Every submission (demo, poster or pitch) was automatically considered for all awards.</p>

                    <h2 id="audience">Audience's choice award</h2>
                    How well can you generate traffic to your poster? How well can you convince a broad audience? (All attendees will be able to vote twice; one time for each poster session.)
                    <div className="row">
                        <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Emma Depuydt with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077052"><i>Investigating the effect of template head models on Event-Related Potential source localization</i></a><br></br>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077052"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.15077052.svg" alt="DOI"></img></a></p>
                        </div>
                        <div className="col-sm-5">
                            <h3>Awarded by</h3>
                            <p>Melanie Verstraete (FEARS Organising Committee)</p>
                        </div>
                    </div>

                    <h2 id="poster">Best poster award</h2>

                    How do you make your work understandable for laymen? All types of contributions are eligible for this award. Two prizes will be awarded: one for posters and a second one for pitches.
                    <div className="row">
                        <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Tim Theys with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077072"><i>The design and evaluation of a personal data store application</i></a><br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077072"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.15077072.svg" alt="DOI"></img></a></p>
                        </div>
                        <div className="col-sm-5">
                            <h3>Awarded by</h3>
                            <p>Jeroen Ongenae & Roger Van Hecke (FEA Communications Officers)</p>
                        </div>
                    </div>

                    <h2 id="pitch">Best pitch award</h2>

                    How do you make your work understandable for laymen? All types of contributions are eligible for this award. Two prizes will be awarded: one for posters and a second one for pitches.
                    <div className="row">
                        <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Kaat Janssens with <i>Interior Insulation: A Risky Business?</i></p>
                        </div>
                        <div className="col-sm-5">
                            <h3>Awarded by</h3>
                            <p>Luc Peeters (<a target="_blank" rel="noopener noreferrer" href="https://www.gentrepreneur.gent/">Gentrepeneur</a>)</p>
                        </div>
                    </div>

                    <h2 id="interdisciplinary">Award for interdisciplinary research </h2>

                    How are you building bridges between several disciplines?
                    <div className="row">
                        <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Yana Maudens with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15076977"><i>Biomimicry of Reptile Eggshells Based on Keratin Nanofibrous Membranes</i></a><br></br>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15076977"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.15076977.svg" alt="DOI"></img></a>
                            </p>
                        </div>
                        <div className="col-sm-5">
                            <h3>Awarded by</h3>
                            <p>Arnaud Zonderman (<a target="_blank" rel="noopener noreferrer"
                                                    href="https://www.scimingo.eu/nl">SciMingo</a>)</p>
                            {/*<p>Kim Verhaeghe (<a target="_blank" rel="noopener noreferrer"
                                                 href="https://www.eoswetenschap.eu/">EOS</a>)</p>*/}
                        </div>
                    </div>


                    <h2 id="SDG">Award for link to SDGs (Sustainable Development Goals) </h2>

                    Explain where the focus on sustainability lies in your research.
                    <div className="row">
                        <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Luca Maton with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077097"><i>Evaluating Energy Calculation Methods for Energy-conscious Occupants in Three Belgian Heritage Townhouses</i></a><br></br>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077097"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.15077097.svg" alt="DOI"></img></a></p>
                        </div>
                        <div className="col-sm-5">
                            <h3>Awarded by</h3>
                            <p>Femke Lootens (<a target="_blank" rel="noopener noreferrer" href="https://www.ugent.be/nl/univgent/missie/duurzaamheidsbeleid/green-office-gent">Green Office</a>)</p>
                        </div>
                    </div>

                    <h2 id="impact">Award for research impact</h2>

                    Which effect does your research have beyond academia?
                    <div className="row">
                        <div className="col-sm-7">
                            <h3>Winner</h3>
                            <p>Paulina Govea-Alvarez with <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077087"><i>Electrochemical CO2 Reduction to CO for In-Situ Resource Utilisation on Mars</i></a></p>
                            <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.5281/zenodo.15077087"><img src="https://zenodo.org/badge/DOI/10.5281/zenodo.15077087.svg" alt="DOI"></img></a>
                        </div>
                        <div className="col-sm-5">
                            <h3>Awarded by</h3>
                            <p>Nathan Van Den Bossche (<a target="_blank" rel="noopener noreferrer" href="https://aig.ugent.be/">AIG - Alumni Ingenieurs Gent</a>)</p>
                        </div>
                    </div>

                </article>
            </div>
        </div>
    </Layout>
)

export default Workshops
